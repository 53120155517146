import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";

const PrivacyPolicy = ({ location }) => {
    return (
        <Layout>
            <Seo title={"Privacy Policy"} url={location.href} />
            <div className="container container--py privacy-policy">
                <h1 className="text-h2 text-fw-medium text-clr-primary gap-3x">PRIVACY POLICY</h1>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    M/s. Sedin Technologies Private Limited, a Company registered under the
                    provisions of the Companies Act 2013, and having its registered office at A1/1,
                    49th Street, 7th Ave, Ashok Nagar, Chennai, Tamil Nadu 600083 hereinafter
                    referred to as the “Company” (where such expression shall, unless repugnant to
                    the context thereof, be deemed to include its respective legal heirs,
                    representatives, administrators, permitted successors and assigns).
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The Company ensures steady commitment to your usage of the Platform and privacy
                    with regards to the protection of your invaluable information. This document
                    contains information about the Website{" "}
                    <a href="https://sedintechnologies.com">www.sedintechnologies.com</a>{" "}
                    hereinafter referred to as the “Platform”.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    In order to provide the User with our uninterrupted use of our Platform, we may
                    collect and, in some circumstances, disclose information about you with your
                    permission. To ensure better protection of the User’s privacy, we provide this
                    notice explaining our information collection and disclosure policies, and the
                    choices the User may make about the way their information is collected and used.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <b>
                        This Privacy Policy shall be in compliance with the relevant User’s
                        applicable Data Protection Laws and any and all provisions that may read to
                        the contrary shall be deemed to be void and unenforceable as of that date.
                        If you do not agree with the terms and conditions of our Privacy Policy,
                        including in relation to the manner of collection or use of your
                        information, please do not use or access the Site. If you have any questions
                        or concerns regarding this Privacy Policy, you should contact our Customer
                        Support Desk at{" "}
                        <a href="mailto:marketing@sedintechnologies.com">
                            marketing@sedintechnologies.com
                        </a>
                    </b>
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">1. DEFINITIONS</h2>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        “We”, “Our”, and “Us” shall mean and refer to the Domain and/or the Company,
                        as the context so requires.
                    </li>
                    <li>
                        “You”, “Your”, “Yourself”, “User”, shall mean and refer to natural and legal
                        individuals who use the Platform and who is competent to enter into binding
                        contracts, as per Indian laws.
                    </li>
                    <li>
                        “Services”, shall refer to the platform that provides consulting services
                        relating to technology and software development. A detailed explanation
                        shall be provided in Clause 4 of the Terms of Service (which can be found
                        separately on our website).
                    </li>
                    <li>
                        “Third Parties” refer to any Application, Legal Entity, Company or
                        individual apart from the User and the Company.
                    </li>
                    <li>
                        “Personal Information” shall mean and refer to any personally identifiable
                        information that we may collect from you such as Name, Email Id, Mobile
                        number, Password, Photo, etc.
                    </li>
                    <li>
                        “Sensitive personal data” means passwords, financial information, physical,
                        physiological, or mental health conditions, sexual orientation, medical
                        records and history, and biometric information. However, it does not include
                        any personal data that is freely available or accessible in the public
                        domain, furnished under the Right to Information Act, 2005, or under any
                        other law in force.
                    </li>
                    <li>
                        “Platform” refers to the Website{" "}
                        <a href="http://www.sedintechnologies.com">www.sedintechnologies.com</a>{" "}
                        which provides consulting services relating to technology and software
                        development.
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    2. INFORMATION WE COLLECT
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    We are committed to respecting our User’s online privacy. We further recognize
                    your need for appropriate protection and management of any Personal Information
                    you share with us. We may collect the following information directly or through
                    third parties:
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        Personal data such as, but not limited to, Name, Email Id, Password and
                        Mobile Number.
                    </li>
                    <li>Details of Platform usage for analytics.</li>
                </ul>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    This privacy policy also applies to data we collect from users who visit the
                    Platform for reference purposes and does not have to be a User with a
                    contractual agreement with the Company. We collect, including, but not limited
                    to, browsing behaviour, IP Addresses, and pages viewed. We also collect and
                    store personal information provided by You from time to time on the Platform. We
                    only collect and use such information from you that we consider necessary for
                    achieving a seamless, efficient, and safe experience, customized to your needs
                    including:
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>To enable the viewing of content in your interest.</li>
                    <li>
                        To communicate the necessary account and service-related information from
                        time to time
                    </li>
                    <li>
                        To allow you to receive quality customer care services and data Collection
                    </li>
                    <li>To comply with applicable laws, rules and regulations</li>
                </ul>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Where any third parties are engaged by the Company to provide certain services
                    for the platform, such third parties may collect information relating to the
                    User as is reasonably necessary by the Company to maintain the Platform and such
                    information may be shared with such third party. We also do use your contact
                    information to send you offers based on your interests and prior activity and to
                    view the content preferred by you. The Company may also use contact information
                    internally to direct its efforts for Platform improvement but shall immediately
                    delete all such information upon withdrawal of your consent for the same through
                    the ‘unsubscribe’ button or through an email to be sent to{" "}
                    <a href="mailto:marketing@sedintechnologies.com">
                        marketing@sedintechnologies.com
                    </a>
                    .
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    To the extent possible, we provide You with the option of not divulging any
                    specific information that you wish for us to not collect, store, or use. You may
                    also choose not to use a particular service or feature on the Platform and
                    opt-out of any non-essential communications from the platform.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Further, transacting over the internet has inherent risks which can only be
                    avoided by you following security practices yourself, such as not revealing
                    account/login related information to any other person and informing our customer
                    care team about any suspicious activity or where your account has/may have been
                    compromised.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    If you are a User from the EU region, the personal data provided by you shall be
                    processed in compliance to the EU GDPR Policy and any transfer of such
                    information outside the EU territory shall be made to an equivalent privacy
                    protected area.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    3. OUR USE OF YOUR INFORMATION
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The information provided by you shall be used to provide and improve the service
                    for you and all users.
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        For providing the service as detailed in Clause 4 of Terms of Service to
                        partnered clients.
                    </li>
                    <li>For maintaining an internal record.</li>
                    <li>For enhancing the Services provided.</li>
                </ul>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    For more details about the nature of such communications, please refer to our
                    Terms of Service. Further, your personal data and Sensitive Personal data may be
                    collected and stored by Us for internal record.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    We, on behalf of third parties, use your tracking information such as IP
                    addresses, and or Device ID to help identify you and to gather broad demographic
                    information and make further services available to you.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    We will not sell, licenses or trade Your personal information. We will not share
                    your personal information with others unless they are acting under our
                    instruction, or we are required to do so by law.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Information collected via our server logs includes users&#39; IP addresses and
                    the pages visited; this will be used to manage the web system and troubleshoot
                    problems. We also use third-party analytics, tracking, optimization and
                    targeting tools to understand how users engage with our Platform so that we can
                    improve it and cater personalized content/ads according to their preferences.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    4. HOW INFORMATION IS COLLECTED
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Before or at the time of collecting personal information, we will identify the
                    purposes for which information is being collected. If the same is not identified
                    to you, you have the right to request the Platform to elucidate the purpose of
                    collection of said personal information, pending the fulfillment of which you
                    shall not be mandated to disclose any information whatsoever.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    We will collect and use your personal information solely with the objective of
                    fulfilling those purposes specified by us, within the scope of the consent of
                    the individual concerned or as required by law. We will only retain personal
                    information as long as necessary for the fulfilment of those purposes. We will
                    collect personal information by lawful and fair means and with the knowledge and
                    consent of the individual concerned.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Personal data should be relevant to the purposes for which it is to be used,
                    and, to the extent necessary for those purposes, should be accurate, complete,
                    and up to date.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    5. EXTERNAL LINKS ON THE PLATFORM
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The Platform may include third-party advertisements, hyperlinks to other
                    websites, applications, content, or resources, including advertisements,
                    hyperlinks, applications, content or resources of the Company itself. You
                    acknowledge and agree that we are not responsible for the availability of any
                    such external sites or resources, and do not endorse any advertising, services,
                    or other materials on or available from such platform or resources. You
                    acknowledge and agree that We are not liable for any loss or damage which may be
                    incurred by you as a result of the availability of those external sites or
                    resources, or as a result of any reliance placed by you on the completeness,
                    accuracy or existence of any advertising, services or other materials on, or
                    available from, such websites or resources. These external websites and resource
                    providers may have their own privacy policies governing the collection, storage,
                    retention, and disclosure of Your Personal Information that You may be subject
                    to. We recommend that you enter the external website and review their privacy
                    policy.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    6. GOOGLE ANALYTICS
                </h2>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        We use Google Analytics to help us to understand how you make use of our
                        content and work out how we can make things better. These cookies follow
                        your progress through us, collecting anonymous data on where you have come
                        from, which pages you visit, and how long you spend on the site. This data
                        is then stored by Google to create reports. These cookies do not store your
                        personal data.
                    </li>
                    <li>
                        The information generated by the cookie about your use of the Platform,
                        including your IP address, may be transmitted to and stored by Google on
                        servers in the United States or anywhere else in the world. Google may use
                        this information for the purpose of evaluating your use of the website,
                        compiling reports on website activity for us and providing other services
                        relating to website activity and internet usage. Google may also transfer
                        this information to third parties where required to do so by law, or where
                        such third parties process the information on Google&#39;s behalf. Google
                        will not associate your IP address with any other data held by Google. By
                        using this website, you consent to the processing of data about you by
                        Google in the manner and for the purposes set out above.
                    </li>
                    <li>
                        The Google website contains further information about Analytics and a copy
                        of{" "}
                        <a
                            href="https://marketingplatform.google.com/about/analytics/terms/us/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Google&#39;s privacy policy pages.
                        </a>
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">7. YOUR RIGHTS</h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Unless subject to an exemption, you have the following rights with respect to
                    your personal data:
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        The right to request a copy of your personal data which we hold about you
                    </li>
                    <li>
                        The right to request for any correction to any personal data if it is found
                        to be inaccurate or out of date
                    </li>
                    <li>The right to withdraw Your consent to the processing at any time</li>
                    <li>The right to object to the processing of personal data</li>
                    <li>The right to lodge a complaint with a supervisory authority</li>
                    <li>
                        The right to obtain information as to whether personal data are transferred
                        to a third Party or to an international organization
                    </li>
                </ul>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Where you hold an account with any of our services, you are entitled to a copy
                    of all personal data which we hold in relation to you. You are also entitled to
                    request that we restrict how we use your data in your account when you log in.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    8. CONFIDENTIALITY
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    You further acknowledge that the Platform may contain information that is
                    designated confidential by us and that you shall not disclose such information
                    without our prior written consent. Your information is regarded as confidential
                    and therefore will not be divulged to any third party, unless if legally
                    required to do so to the appropriate authorities. We will not sell, share, or
                    rent your personal information to any third party or use your e-mail address for
                    unsolicited mail. Any emails sent by us will only be in connection with the
                    marketing and promotion of our services, and you retain sole discretion to seek
                    for discontinuation of such communications at any point of time.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    9. OUR DISCLOSURE OF YOUR INFORMATION
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    We may host surveys for survey creators for our platform who are the owners and
                    users of your survey responses. We do not own or sell your responses. Anything
                    you expressly disclose in your responses will be disclosed to survey creators.
                    Please contact the survey creator directly to better understand how they might
                    share your survey responses.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Information collected will not be considered as sensitive if it is freely
                    available and accessible in the public domain or is furnished under the Right to
                    Information Act, 2005, any rules made thereunder or any other law for the time
                    being in force.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Due to the existing regulatory environment, and the evolving nature of law, we
                    cannot ensure that all your private communications and other personally
                    identifiable information will never be disclosed in ways not otherwise described
                    in this Privacy Policy. By way of example (without limiting and foregoing), we
                    may be forced to disclose information to the government, law enforcement
                    agencies or third parties. Therefore, although we use industry-standard
                    practices to protect your privacy, we do not promise, and you should not expect,
                    that your personally identifiable information or private communications would
                    always remain private. We do however assure you that any and all disclosure of
                    your personally identifiable sensitive information shall be personally intimated
                    to you through an email sent to your provided email address.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    As a matter of policy, we do not sell or rent any personally identifiable
                    information about you to any third party. However, the following describes some
                    of the ways that your personally identifiable information may be disclosed:
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        External Service Providers: There may be several services offered by
                        external service providers that help you use our Platform. If you choose to
                        use these services, and in the course of doing so, disclose information to
                        the external service providers, and/or grant them permission to collect
                        information about you, then their use of your information is governed by
                        their privacy policy.
                    </li>
                    <li>
                        Law and Order: We cooperate with law enforcement inquiries, as well as other
                        third parties to enforce laws, such as intellectual property rights, fraud,
                        and other rights. We can (and you authorize us to) disclose any information
                        about you to law enforcement and other government officials as we, in our
                        sole discretion, believe necessary or appropriate, in connection with an
                        investigation of fraud, intellectual property infringements, or other
                        activity that is illegal or may expose us or you to legal liability.
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">10. SECURITY</h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    We treat data as an asset that must be protected against loss and unauthorized
                    access. We employ many different security techniques to protect such data from
                    unauthorized access by members inside and outside the Company. We follow
                    generally accepted industry standards to protect the Personal Information
                    submitted to us and information that we have accessed.
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    However, no security system is impenetrable. Our Company cannot guarantee the
                    security of our database, nor can we guarantee that information you provide
                    won’t be intercepted while being transmitted to the Company over the Internet.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">11. SEVERABILITY</h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Each paragraph of this Privacy Policy shall be and remain separate from,
                    independent of and severable from all and any other paragraphs herein except
                    where otherwise expressly indicated or indicated by the context of the
                    agreement. The decision or declaration that one or more of the paragraphs are
                    null and void shall have no effect on the remaining paragraphs of this privacy
                    policy.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">12. AMENDMENT</h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Our Privacy Policy may change from time to time. The most current version of the
                    policy will govern our use of your information and will always be at the
                    Platform. Any amendments to this Policy shall be deemed as accepted by the User
                    on their continued use of the Platform.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    13. AUTOMATED DECISION MAKING
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    As a responsible Company, we do not use automatic decision-making or profiling.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">14. CONTACT US</h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    If you have any questions or concerns regarding this privacy policy, you should
                    contact us by sending an e-mail to please email to [
                    <a href="mailto:marketing@sedintechnologies.com">
                        marketing@sedintechnologies.com
                    </a>
                    ].
                </p>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Information provided on the website may not be 100% accurate and may be provided
                    for promotional purposes of the business
                </p>
            </div>
        </Layout>
    );
};

export default PrivacyPolicy;
